import React from 'react'
import Layout from '../components/Layout'

const IndexPage = () => (
  <Layout>
    <div>
      <p>Welcome</p>
    </div>
  </Layout>
)

export default IndexPage
